@each $pixel in (0 1 2 3 5 8 10 15 20 25 30 35 40 45 50 60 70 80 90 100 120 150 200 220 250 300) {
	@each $side in (top right bottom left) {
		.marg-#{$side}-#{$pixel} {
			margin-#{$side}: #{$pixel}px;
		}

		.pad-#{$side}-#{$pixel} {
			padding-#{$side}: #{$pixel}px;
		}
	}

	.pad-#{$pixel} {
		padding: #{$pixel}px;
	}

	.marg-#{$pixel} {
		margin: #{$pixel}px;
	}

	.min-width-#{$pixel} {
		min-width: #{$pixel}px !important;
	}

	.width-#{$pixel} {
		width: #{$pixel}px !important;
	}

	.min-height-#{$pixel} {
		min-height: #{$pixel}px !important;
	}

	.max-height-#{$pixel} {
		max-height: #{$pixel}px !important;
	}

	.max-width-#{$pixel} {
		max-width: #{$pixel}px !important;
	}

	.marg-horiz-#{$pixel} {
		margin-left: #{$pixel}px;
		margin-right: #{$pixel}px;
	}

	.marg-vert-#{$pixel} {
		margin-top: #{$pixel}px;
		margin-bottom: #{$pixel}px;
	}

	.pad-horiz-#{$pixel} {
		padding-left: #{$pixel}px;
		padding-right: #{$pixel}px;
	}

	.pad-vert-#{$pixel} {
		padding-top: #{$pixel}px;
		padding-bottom: #{$pixel}px;
	}
}

.no-margin {
	margin: 0 !important;
}

.full-height {
	height: 100%;
}

.full-screen {
	height: 100vh !important;
}

.overflow-hidden {
	overflow: hidden;
}
